<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Nova Mensagem</template>
        <template #content>
          <!-- Manter DataTable se necessário -->
          <!-- Aqui começa a estrutura do formulário -->
          <div class="p-fluid formgrid grid">
            <div class="flex flex-row flex-wrap col-12">
              <div class="md:col-4">
                <label for="perfil" class="mb-3 block"
                  >Perfil de exibição</label
                >
                <div id="perfil" class="flex flex-wrap gap-4 items-center">
                  <div class="flex items-center mr-2">
                    <Checkbox
                      v-model="mensagem.gestao"
                      inputId="gestao"
                      name="gestao"
                      :binary="true" />
                    <label for="gestao" class="ml-1">Gestão</label>
                  </div>
                  <div class="flex items-center mr-2">
                    <Checkbox
                      v-model="mensagem.consignataria"
                      inputId="consignataria"
                      name="consignataria"
                      :binary="true" />
                    <label for="consignataria" class="ml-1"
                      >Consignatária</label
                    >
                  </div>
                  <div class="flex items-center mr-2">
                    <Checkbox
                      v-model="mensagem.servidor"
                      inputId="servidor"
                      name="servidor"
                      :binary="true" />
                    <label for="servidor" class="ml-1">Servidor</label>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  mensagem.consignataria &&
                  !mensagem.gestao &&
                  !mensagem.servidor
                "
                class="col-6 md:col-6">
                <label for="consignataria">Consignatária</label>
                <Dropdown
                  id="consignataria"
                  v-model="mensagem.consignatariaSelecionada"
                  data-key="id"
                  optionLabel="razaoSocial"
                  :options="consignatarias"
                  showClear
                  :filter="true"
                  filterPlaceholder="Procure uma Consignatária"
                  :emptyFilterMessage="'Nenhuma encontrada'"
                  placeholder="Para Mensagem Direta, Selecione uma Consignatária">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <span>
                        {{ slotProps.value.cnpj }}
                        {{ slotProps.value.razaoSocial }}
                      </span>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>

                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <span>
                        {{ slotProps.option.cnpj }}
                        {{ slotProps.option.razaoSocial }}
                      </span>
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div class="field col-6 md:col-3">
              <label for="dataInicio">Data Início</label>
              <Calendar
                v-model="mensagem.dataInicio"
                :showIcon="true"
                :disabledDates="disabledDates"
                :disabled="!isPerfilSelecionado"
                dateFormat="dd/mm/yy"
                placeholder="Data Início" />
            </div>
            <div class="field col-6 md:col-3">
              <label for="dataFim">Data Fim</label>
              <Calendar
                v-model="mensagem.dataFim"
                :showIcon="true"
                :disabledDates="disabledDates"
                :disabled="!isPerfilSelecionado"
                dateFormat="dd/mm/yy"
                placeholder="Data Fim" />
            </div>
            <div class="field col-12">
              <label for="texto">Mensagem</label>
              <Textarea
                v-model="mensagem.mensagem"
                :maxlength="600"
                rows="5"
                cols="30"
                placeholder="Digite sua mensagem" />
            </div>
          </div>
          <!-- Botão Salvar -->
          <Button label="Salvar" icon="pi pi-save" @click="salvarMensagem" />
        </template>
      </Card>
      <Card
        v-for="message in savedMessages"
        :key="message.id"
        class="saved-message">
        <template #title>
          <span> {{ `Mensagem ${message.id}` }} </span>
          <Button
            v-if="isMensagemEditavel(message.dataInicio, message.dataFim)"
            v-tooltip.right="'Editar'"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning mr-2"
            @click="showEditarMensagemDialog(message)" />
        </template>
        <template #content>
          <p><strong>Id:</strong> {{ message.id }}</p>
          <p>
            <strong>Data Início:</strong>
            {{ formatarDataParaExibir(message.dataInicio) }}
          </p>
          <p>
            <strong>Data Fim:</strong>
            {{ formatarDataParaExibir(message.dataFim) }}
          </p>
          <p><strong>Mensagem:</strong> {{ message.mensagem }}</p>
          <p>
            <strong>Perfis de Exibição:</strong>
            {{
              formatarPerfisParaExibir(
                message.gestao,
                message.servidor,
                message.consignataria,
              )
            }}
            {{
              message.consignatariaSelecionada
                ? ' - ' + message.consignatariaSelecionada.nomeFantasia
                : ''
            }}
          </p>
        </template>
      </Card>
      <Paginator
        :rows="rowsPerPage"
        :totalRecords="totalRecords"
        :pageLinkSize="3"
        :rowsPerPageOptions="[5, 10]"
        @page="onPageChange"></Paginator>
    </div>
    <Dialog
      :visible.sync="editarMensagemDialog"
      :modal="true"
      :containerStyle="{ width: '70vw' }"
      @hide="hideEditarMensagemDialog">
      <template #header>
        <span>Editar Mensagem {{ mensagem.id }}</span>
      </template>

      <!-- Manter DataTable se necessário -->
      <!-- Aqui começa a estrutura do formulário -->
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-3">
          <label for="dataInicio">Data Início</label>
          <Calendar
            v-model="mensagem.dataInicio"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            placeholder="Data Início" />
        </div>
        <div class="field col-6 md:col-3">
          <label for="dataFim">Data Fim</label>
          <Calendar
            v-model="mensagem.dataFim"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            placeholder="Data Fim" />
        </div>
        <div class="flex flex-row flex-wrap col-12">
          <div class="md:col-4">
            <label for="perfil" class="mb-3 block">Perfil de exibição</label>
            <div id="perfil" class="flex flex-wrap gap-4 items-center">
              <div class="flex items-center mr-2">
                <Checkbox
                  v-model="mensagem.gestao"
                  inputId="gestao"
                  name="gestao"
                  :binary="true" />
                <label for="gestao" class="ml-1">Gestão</label>
              </div>
              <div class="flex items-center mr-2">
                <Checkbox
                  v-model="mensagem.consignataria"
                  inputId="consignataria"
                  name="consignataria"
                  :binary="true" />
                <label for="consignataria" class="ml-1">Consignatária</label>
              </div>
              <div class="flex items-center mr-2">
                <Checkbox
                  v-model="mensagem.servidor"
                  inputId="servidor"
                  name="servidor"
                  :binary="true" />
                <label for="servidor" class="ml-1">Servidor</label>
              </div>
            </div>
          </div>
          <div
            v-if="
              mensagem.consignataria && !mensagem.gestao && !mensagem.servidor
            "
            class="col-6 md:col-6">
            <label for="consignataria">Consignatária</label>
            <Dropdown
              id="consignataria"
              v-model="mensagem.consignatariaSelecionada"
              data-key="id"
              optionLabel="razaoSocial"
              :options="consignatarias"
              showClear
              :filter="true"
              filterPlaceholder="Procure uma Consignatária"
              :emptyFilterMessage="'Nenhuma encontrada'"
              placeholder="Para Mensagem Direta, Selecione uma Consignatária">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <span>
                    {{ slotProps.value.cnpj }}
                    {{ slotProps.value.razaoSocial }}
                  </span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>

              <template #option="slotProps">
                <div class="flex align-items-center">
                  <span>
                    {{ slotProps.option.cnpj }}
                    {{ slotProps.option.razaoSocial }}
                  </span>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="field col-12">
          <label for="texto">Mensagem</label>
          <Textarea
            v-model="mensagem.mensagem"
            :maxlength="600"
            rows="5"
            cols="30"
            placeholder="Digite sua mensagem" />
        </div>
      </div>

      <!-- Botão Salvar -->
      <template #footer>
        <Button
          label="Salvar"
          icon="pi pi-save"
          @click="
            () => {
              salvarMensagem()
              hideEditarMensagemDialog()
            }
          " />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'
import Textarea from 'primevue/textarea'
import Paginator from 'primevue/paginator'
import MensagemService from '@/service/MensagemService.js'
import ConsignatariaService from '@/service/ConsignatariaService.js'

export default {
  components: {
    Textarea,
    Paginator,
  },
  data() {
    return {
      editarMensagemDialog: false,
      disabledDates: [],
      isPerfilSelecionado: false,
      mensagem: {
        id: '',
        dataInicio: '',
        dataFim: '',
        mensagem: '',
        gestao: false,
        consignataria: false,
        servidor: false,
        consignatariaSelecionada: null,
      },
      savedMessages: [],
      currentPage: 1,
      rowsPerPage: 5,
      totalRecords: 0,
      consignatarias: [],
    }
  },
  watch: {
    'mensagem.gestao': function () {
      this.atualizaDisabledDates()
      this.atualizaIsPerfilSelecionado()
    },
    'mensagem.consignataria': function () {
      this.atualizaDisabledDates()
      this.atualizaIsPerfilSelecionado()
    },
    'mensagem.servidor': function () {
      this.atualizaDisabledDates()
      this.atualizaIsPerfilSelecionado()
    },
    'mensagem.consignatariaSelecionada': function () {
      this.atualizaDisabledDates()
      this.atualizaIsPerfilSelecionado()
    },
  },
  created() {
    this.service = new MensagemService(this.$http)
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.loadMessages()
    this.loadConsignatarias()
  },

  methods: {
    loadMessages() {
      this.service
        .buscarMensagens(this.currentPage, this.rowsPerPage)
        .then((response) => {
          this.savedMessages = response.data.content
          this.totalRecords = response.data.totalElements
        })
        .catch((error) => {
          console.error('Erro ao carregar mensagens:', error)
        })
    },

    loadConsignatarias() {
      this.consignatariaService.getConsignatariasAtivas().then((res) => {
        this.consignatarias = res
      })
    },

    formatarData(data) {
      if (data) {
        let data_americana = moment(data, 'YYYY-MM-DD').toDate()
        return data_americana
      }
      return null
    },

    formatarDataParaExibir(data) {
      if (data) {
        let data_formatada = moment(data).format('DD/MM/YYYY')
        return data_formatada
      }
      return null
    },

    formatarPerfisParaExibir(gestao, servidor, consignataria) {
      const perfisMap = {
        gestao: gestao ? 'Gestão' : '',
        consignataria: consignataria ? 'Consignatária' : '',
        servidor: servidor ? 'Servidor' : '',
      }

      return Object.values(perfisMap)
        .filter((perfil) => perfil)
        .join(', ')
    },

    salvarMensagem() {
      // Formata datas
      const dataInicioFormatada = this.formatarData(this.mensagem.dataInicio)
      const dataFimFormatada = this.formatarData(this.mensagem.dataFim)
      if (this.mensagem.gestao || this.mensagem.servidor)
        this.mensagem.consignatariaSelecionada = null
      const mensagemParaSalvar = {
        ...this.mensagem,
        dataInicio: dataInicioFormatada,
        dataFim: dataFimFormatada,
      }

      // Iniciar chamada de serviço para salvar mensagem
      this.service
        .saveMensagem(mensagemParaSalvar)
        .then((response) => {
          if (response && response.data) {
            const savedMessage = response.data
            this.savedMessages.push(savedMessage)
            this.exibeToast('save')
            this.resetForm()
            this.loadMessages() // Recarrega as mensagens após salvar
          } else {
            console.error('Resposta do servidor inesperada: ', response)
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar a mensagem: ', error)
          this.exibeToast('save-error', error.response.data.message)
        })
      this.loadMessages()
    },

    onPageChange(event) {
      this.currentPage = event.page + 1 // Paginator do PrimeVue começa a contar páginas do 0
      this.rowsPerPage = event.rows
      this.loadMessages() // Carrega as mensagens da página selecionada
    },

    exibeToast(tipo, msg) {
      if (tipo === 'save') {
        this.$toast.add({
          severity: 'success',
          summary: 'Configuração salva com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'save-error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'update') {
        this.$toast.add({
          severity: 'success',
          summary: 'Configuração Atualizada com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'update-error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      }
    },

    resetForm() {
      this.mensagem = {
        id: '',
        dataInicio: '',
        dataFim: '',
        mensagem: '',
        gestao: false,
        consignataria: false,
        servidor: false,
        consignatariaSelecionada: null,
      }
    },
    showEditarMensagemDialog(message) {
      this.mensagem = { ...message }
      this.mensagem.dataInicio = new Date(message.dataInicio)
      this.mensagem.dataFim = new Date(message.dataFim)
      this.editarMensagemDialog = true
    },
    hideEditarMensagemDialog() {
      this.editarMensagemDialog = false
      this.resetForm()
    },
    isMensagemEditavel(dataInicio, dataFim) {
      const today = new Date()
      const startDate = new Date(dataInicio) //'Z' Pra setar a data como UTC
      const endDate = new Date(dataFim)

      today.setHours(0, 0, 0, 0)

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      return today <= endDate
    },

    atualizaDisabledDates() {
      let allDisabledDates = []

      if (this.mensagem.gestao)
        allDisabledDates.push(...this.desabilitaDatas('gestao').dates)
      if (this.mensagem.consignataria)
        allDisabledDates.push(...this.desabilitaDatas('consignataria').dates)
      if (this.mensagem.servidor)
        allDisabledDates.push(...this.desabilitaDatas('servidor').dates)
      if (this.mensagem.consignatariaSelecionada)
        allDisabledDates.push(...this.desabilitaDatas('consignataria').dates)
      let uniqueDisabledDates = Array.from(
        new Set(allDisabledDates.map((date) => date.toISOString())),
      ).map((dateString) => new Date(dateString))

      this.disabledDates = uniqueDisabledDates
    },

    desabilitaDatas(perfil) {
      let disabledDates = []

      this.savedMessages.forEach((message) => {
        if (
          message.consignatariaSelecionada != null &&
          this.mensagem.consignatariaSelecionada != null
        ) {
          if (
            message.consignatariaSelecionada.id ==
            this.mensagem.consignatariaSelecionada.id
          ) {
            let startDate = new Date(message.dataInicio)
            let endDate = new Date(message.dataFim)

            let currentDate = startDate
            while (currentDate <= endDate) {
              disabledDates.push(new Date(currentDate))
              currentDate.setDate(currentDate.getDate() + 1)
            }
          }
        } else if (
          message[perfil] === true &&
          this.mensagem.consignatariaSelecionada == null &&
          !message.consignatariaSelecionada
        ) {
          let startDate = new Date(message.dataInicio)
          let endDate = new Date(message.dataFim)

          let currentDate = startDate
          while (currentDate <= endDate) {
            disabledDates.push(new Date(currentDate))
            currentDate.setDate(currentDate.getDate() + 1)
          }
        }
      })
      return { dates: disabledDates }
    },
    atualizaIsPerfilSelecionado() {
      this.isPerfilSelecionado =
        this.mensagem.gestao ||
        this.mensagem.consignataria ||
        this.mensagem.servidor
    },
  },
}
</script>

<style scoped>
/* Estilos adicionais conforme necessário... */
.mensagem-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.mensagem-form > * {
  margin-bottom: 10px;
}

.saved-message {
  /* Estilo para as mensagens salvas */
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
